import { Link } from "gatsby"
import React, { useRef, useState } from "react"
import { Col, Container } from "react-bootstrap"
import { Autoplay } from "swiper" // Import the Autoplay module
import "swiper/css"
import "swiper/css/autoplay" // Import autoplay styles
import { Swiper, SwiperSlide } from "swiper/react"
import SVGIcon from "../SVGIcon"

const TestimonialV7 = props => {
  const swiperRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(1)
  const totalSlides = props.testimonial.testimonialDatas.length

  const goToSlide = index => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index + 1)
      setCurrentIndex(index)
    }
  }

  return (
    <section className="multi-testimonal mb100">
      <Container className="Layout-container d-flex flex-wrap  row mx-auto">
        <Col lg={5} className="testimonal-l">
          <h2 className="testimonal-heading">
            {props.testimonial.testimonialSectionHeading}
          </h2>
          <div className="testimonial-image">
            {props.testimonial.testimonialImage.map(img => {
              return <img src={img.url} alt="ranking" className="w-100" />
            })}
          </div>

          {props.secondaryLink && (
            <div className="second-link a12">
              <Link to={props.secondaryLink} className="link">
                {props.secondaryBtnText}
                <SVGIcon name="hypertext-arrow-right" className="arrow-right" />
              </Link>
            </div>
          )}
        </Col>
        <Col lg={7} className="testimonal-r">
          <Swiper
            loop={true}
            className="mySwiper"
            speed={750}
            spaceBetween={30}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loopAdditionalSlides={1}
            loopFillGroupWithBlank={false}
            onSlideChange={swiper => setCurrentIndex(swiper.realIndex + 1)}
            ref={swiperRef}
            modules={[Autoplay]}
          >
            {props.testimonial.testimonialDatas.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="card d-flex flex-column">
                  <div className="icon">
                    <img
                      src="https://media.graphassets.com/HxDfSXBHTxqiQlEZFnF5"
                      alt="comma"
                      className="w-100"
                    />
                  </div>
                  <p className="content font-roboto fw-700">{item.content}</p>
                  <div className="name-title-image d-flex">
                    <div className="name-image">
                      <img
                        src={item.icon?.url || "default-image.jpg"}
                        alt={item.name}
                        className="w-100"
                      />
                    </div>
                    <div className="name-title">
                      <h4 className="name fw-800">{item.name}</h4>
                      <p className="title p14">{item.title}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-status d-flex flex-wrap">
            <p className="status p16">
              {currentIndex} / {totalSlides}
            </p>
            <div className="full-progress-bar d-flex">
              {[...Array(totalSlides)].map((_, index) => (
                <div
                  key={index}
                  className={`progress-segment ${index}`}
                  onClick={() => goToSlide(index + 1)}
                  style={{
                    backgroundColor:
                      currentIndex === index + 1 ? "#F05" : "#FFBFD4",
                  }}
                ></div>
              ))}
            </div>
          </div>
        </Col>
      </Container>
    </section>
  )
}

export default TestimonialV7
